<template>
    <div class="page" ref="scrollView">
    <nav-bar></nav-bar>

        <div class="message-list">
            <template v-for="(item, index) in textData" :key="index">
                <div class="item user" v-if="item.role === 'user'">
                    <img class="avatar" :src="userStore.userInfo.photo" alt="">
                    <div class="content" style="white-space: pre-wrap;">
                        {{ item.content }}
                    </div>
                </div>
                <div class="item " v-if="item.role === 'assistant'">
                    <div class="assistant">
                        <img class="avatar" src="@assets/logo1.png" alt="">
                        <div>
                            <div class="content">
                                <div class="loading" v-if="!item.content">
                                    <img src="@assets/loading.png" alt="">
                                    回答中...
                                </div>
                                <div style="white-space: pre-wrap;">
                                    {{ item.content }}
                                    <div class="btns" v-if="(index === textData.length - 1) && item.status != 'connect'">
                                        <div class="aiInfo">由AI生成</div>
                                        <img @click="reload" src="@assets/reload.png" alt="">
                                        <div class="line"></div>
                                        <img :data-clipboard-text="item.content" data-clipboard-target="#copy"
                                            @click="copy(item.content)" src="@assets/copy.png" alt="">
                                        <div class="line"></div>
                                        <img @click="goJubao" src="@assets/jubao.png" alt="">
                                        
                                    </div>
                                    <div class="btns" v-if="index !== (textData.length - 1)">
                                        <img :data-clipboard-text="item.content" data-clipboard-target="#copy"
                                            @click="copy(item.content)" src="@assets/copy.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="stop-btn" v-if="item.status == 'connect'" @click="stop">停止输出</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <bottom-bar @send="send" :showBottom="showBottom"></bottom-bar>
</template>

<script setup>
let tokenUrl = sessionStorage.getItem('tokenUrl')
import NavBar from '../../components/nav-bar.vue';
import { watch, onMounted, nextTick, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import BottomBar from '@/components/bottom-bar';
import { useMessage } from '@/hooks/message.js'
import { showToast } from 'vant';
import { useMessageStore } from '@/stores/message'
import { useUserStore } from '@/stores/user'
import { useRoute,useRouter } from 'vue-router'
import { addConversation } from './api.js'
const route = useRoute()
const router = useRouter()
let { textData, total_res, sendMessage, stop, init, answerId, setAnswerId, reload, showBottom } = useMessage()
const messageStore = useMessageStore()
const userStore = useUserStore()
const scrollView = ref()
const { toClipboard } = useClipboard()

onMounted(() => {
    if (route.query.id) {
        init(route.query.id)
        messageStore.title = route.query.title
        return
    }
    if (messageStore.answerId) {
        return
    }
    if (route.query.text) {
        addNewMessage(route.query.text)
    }
})

watch(total_res, () => {
    if (scrollView.value) {
        setTimeout(() => {
            scrollView.value.scrollTop = scrollView.value.scrollHeight
        }, 200)
    }
})
watch(showBottom, () => {
    console.log(showBottom.value,'showBottom.value')
})
watch(textData, () => {
    if (scrollView.value) {
        setTimeout(() => {
            scrollView.value.scrollTop = scrollView.value.scrollHeight

        }, 200)
    }
})
watch(route, () => {
    if (route.query.id) {
        init(route.query.id)
        return
    }
    if (route.query.text) {
        send(route.query.text)
        // addNewMessage(route.query.text)
    }
})
function goJubao(){
    window.location.href = tokenUrl;
    localStorage.setItem('reloadPage', 1)
}
function send(text) {
    messageStore.status = 'connect'
    if (!answerId.value) {
        addNewMessage(text)
    } else {
        sendMessage({ text })
    }
}

function addNewMessage(text) {
    addConversation({
        answerTitle: text
    }).then(res => {
        setAnswerId(res.data.answerId)
        messageStore.title = text
        messageStore.answerId = res.data.answerId
        sendMessage({ text })
    })
}
const copy = async (text) => {
    try {
        await toClipboard(text)
        showToast('复制成功')
    } catch (e) {
        console.error(e)
    }
}
</script>

<style lang="less" scoped>
.page {
    height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    &::-webkit-scrollbar {
        width: 0px;
    }
}

.message-list {

    padding: 24px 16px;

    .item {
        margin-bottom: 24px;

        .stop-btn {
            margin-top: 16px;
            width: 80px;
            height: 24px;
            line-height: 24px;
            background: #EFF6FC;
            border-radius: 12px;
            font-size: 12px;
            color: #8891AE;
            text-align: center;
        }

    }
}

.user {
    display: flex;
    .avatar {
        width: 32px;
        height: 32px;
        background: #5381FF;
        border-radius: 50%;
        border: none;
        margin-right: 8px;
    }

    .content {
        margin-top: 8px;
        flex: 1;
        font-size: 16px;
        line-height: 22px;

        color: #494A57;
    }
}

.assistant {
    display: flex;

    .avatar {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        background: #5381FF;
        border-radius: 50%;
        border: none;
    }

    .content {
        margin-top: 8px;
        flex: 1;
        background: #FFFFFF;
        box-shadow: 0 1px 3px 0 #ccdeff33;
        border-radius: 8px;
        padding: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #1D1D2C;
        letter-spacing: 1px;
        .btns {
            .aiInfo{
                position: relative;
                top: -3px;
                width: 80%;
                text-align: left;
                font-size: .9375rem;
                color: #a1a4ae;
            }
            margin-left: auto;
            margin-top: 16px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
           

            .line {
                margin: 0 16px;
                width: 1px;
                height: 16px;
                background: #B3B4BA;
                border-radius: 2px;
                border-radius: 0.5px;
            }

            img {
                width: 16px;
                height: 16px;
            }
        }

        .loading {
            display: flex;
            align-items: center;
            
            font-size: 12px;
            color: #1D1D2C;

            img {
                margin-right: 10px;
                width: 16px;
                height: 16px;
                animation: loading 2s infinite;
                transform: rotate(0);
            }

            @keyframes loading {
                0% {
                    transform: rotate(0);
                }

                100% {
                    transform: rotate(360deg);

                }
            }
        }

    }
}
</style>